import React, { useEffect } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useMemo, useRef } from 'react';
import classNames from 'classnames';
import { webSafeFonts } from 'components/template-designer/config/fonts';
import { GoogleFonts } from 'components/template-designer/models/google-fonts';
import { FontType } from 'components/template-designer/types/font.type';
import Template, { DesignerSettings } from 'components/template-designer/types/template.type';
import EmptyState from 'components/ui-components-cape/EmptyState';
import Illustration from 'components/ui-components-cape/Illustration';
import Translation from 'components/data/Translation';
import Icon from 'components/ui-components-v2/Icon';
import Button from 'components/ui-components-v2/Button';
import { FontPickerFontItem } from './font-picker-font-item';
import { FontPickerAddFontDialog } from './font-picker-add-font-dialog';
import '../styles/font-picker-font-list.scss';

export interface Font {
    fontSource: FontType;
    key: string;
    label: string;
    value: string;
    image?: string;
}

interface Props {
    brandGuide: Template['brandGuide'];
    templateFonts: DesignerSettings['templateFonts'];
    searchTerm: string;
    fontSource: FontType;
    fontFamily?: string | string[];
    onChange: (fontSource: FontType, fontFamily: string, fontLabel: string) => void;
    onChangePreview?: (fontSource: FontType, fontFamily: string) => void;
    setCurrentFontSource: (fontSource: FontType) => void;
}

const FontPickerFontList = ({ brandGuide, templateFonts = [], searchTerm, fontSource, fontFamily, onChange, onChangePreview, setCurrentFontSource }: Props) => {
    const fontFamilyScrollContainer = useRef<HTMLDivElement>(null);
    const originalFontSource = useRef<FontType>(fontSource);

    /**
     * Font families from different sources.
     */
    const fontFamilies: Record<FontType, Font[]> = useMemo(
        () => ({
            standard: webSafeFonts.map((font) => ({
                fontSource: 'standard',
                key: font.value,
                value: font.value,
                label: font.label
            })),
            googleFonts: GoogleFonts.get().map((font) => ({
                fontSource: 'googleFonts',
                key: font.family,
                value: font.family,
                label: font.family,
                image: `${process.env.STATIC_HOSTING_URL}assets/fonts/${encodeURI(font.family)}.svg`
            })),
            ['brandGuide']:
                brandGuide?.fonts
                    .filter((font) => font.brand === 'general')
                    .map((font) => ({
                        fontSource: 'brandGuide',
                        key: font.key,
                        value: font.key,
                        label: font.value
                    })) || [],
            ['brandGuide_brand']:
                brandGuide?.fonts
                    .filter((font) => font.brand !== 'general')
                    .map((font) => ({
                        fontSource: 'brandGuide_brand',
                        key: font.key,
                        value: font.key,
                        label: font.value
                    })) || [],
            templateFonts: templateFonts.map((font) => ({
                fontSource: 'templateFonts',
                key: font.key,
                value: font.value,
                label: font.title
            }))
        }),
        [brandGuide, templateFonts]
    );

    /**
     * Filter the unified font options based on the search term.
     * If no search term is provided, only the fonts from the selected font source are returned.
     */
    const filteredFontFamilyOptions: Font[] = useMemo(() => {
        if (searchTerm === '') {
            return fontFamilies[fontSource] ?? [];
        }

        const search = searchTerm.toLowerCase();
        const allFontFamilies: Font[] = Object.keys(fontFamilies).reduce((acc, key) => [...acc, ...fontFamilies[key]], [] as Font[]);
        return allFontFamilies.filter((font) => font.label.toLowerCase().includes(search));
    }, [fontSource, fontFamilies, searchTerm, fontFamily]);

    /**
     * Virtualizer for the tracks.
     * The makes sure only tracks in view are rendered.
     */
    const fontVisualizer = useVirtualizer({
        count: filteredFontFamilyOptions.length,
        getScrollElement: () => fontFamilyScrollContainer.current,
        estimateSize: () => 30, // Height of the row.
        getItemKey: (index) => filteredFontFamilyOptions[index].value,
        overscan: 1
    });

    /**
     * Scroll to the selected font family.
     */
    useEffect(() => {
        const selectedFontIndex = filteredFontFamilyOptions.findIndex((font) => font.value === fontFamily);
        if (selectedFontIndex === -1) return;

        // Set timeout to make sure the scroll container is rendered.
        setTimeout(() => {
            fontVisualizer.scrollToIndex(selectedFontIndex, {
                align: 'center'
            });
        });
    }, [fontFamily, fontSource]);

    const items = fontVisualizer.getVirtualItems();

    return (
        <div>
            <div
                className={classNames('template-designer__font-picker-font-list', {
                    'template-designer__font-picker-font-list--empty': items.length === 0
                })}
                onMouseLeave={() => {
                    onChangePreview && typeof fontFamily === 'string' && onChangePreview(originalFontSource.current, fontFamily);
                }}
                ref={fontFamilyScrollContainer}>
                {items.length === 0 && (
                    <EmptyState
                        size="small"
                        secondaryText={Translation.get('sidebarRight.inputs.fontPicker.noFonts', 'template-designer')}
                        illustration={<Illustration size="small" illustration="fallback" color="disabled" />}
                    />
                )}

                {items.length > 0 && (
                    <div className="template-designer__font-picker-font-list__container" style={{ height: `${fontVisualizer.getTotalSize()}px` }}>
                        {items.map((option) => {
                            const font = filteredFontFamilyOptions[option.index] as Font;

                            return (
                                <FontPickerFontItem
                                    key={option.key}
                                    option={option}
                                    fontSource={fontSource}
                                    fontFamily={fontFamily}
                                    onSelectFontFamily={(fontSource, fontFamily, fontLabel) => {
                                        onChange(fontSource, fontFamily, fontLabel);
                                        originalFontSource.current = fontSource;
                                    }}
                                    onHover={() => onChangePreview && onChangePreview(fontSource, font.value)}
                                    font={font}
                                />
                            );
                        })}
                    </div>
                )}
            </div>
            {fontSource === 'templateFonts' && (
                <>
                    <FontPickerAddFontDialog
                        trigger={
                            <Button className="template-designer__font-picker-font-list__add-font" startIcon={<Icon>add</Icon>}>
                                {Translation.get('sidebarRight.inputs.fontPicker.addFont', 'template-designer')}
                            </Button>
                        }
                        setCurrentFontSource={setCurrentFontSource}
                        onAdd={onChange}
                    />
                </>
            )}
        </div>
    );
};

export { FontPickerFontList };
