import React from 'react';
import classNames from 'classnames';
import cloneDeep from 'helpers/cloneDeep';
import Animation from 'components/template-designer/types/animation.type';
import Layer from 'components/template-designer/types/layer.type';
import { CustomAndPredefinedAnimations } from 'components/template-designer/types/layerProperties.type';
import { getTemplateData } from 'components/template-designer/helpers/data.helpers';
import { View } from 'components/template-designer/types/template.type';
import TemplateDesignerStore from 'components/template-designer/data/template-designer-store';
import { TimelineHelpers } from 'components/template-designer/helpers/timeline.helpers';
import FormatHelpers from 'components/template-designer/helpers/format.helpers';
import { roundToNearestDecimal } from 'components/template-designer/utils/roundNumbers';
import { SECOND_ROUNDING_PRECISION } from 'components/template-designer/constants';
import { KeyframeDot } from './keyframe-dot';
import { useTimelineDrag } from '../../../hooks/use-timeline-drag';
import { SetTimelineData } from '../../../hooks/use-timeline-data';
import '../styles/keyframe.scss';

interface Props {
    setTimelineData: SetTimelineData;
    keyframe: Animation;
    animation: Animation[];
    isSelected?: boolean;
    isActive?: boolean;
    isKeyframeActive?: boolean;
    parentSelected?: boolean;
    keyframeIndex: number;
    timelineWidth: number;
    layer: Layer;
    animationKey: keyof CustomAndPredefinedAnimations;
    hasOverwrite: boolean;
    dataCyPrefix?: string;
}

const Keyframe = ({
    setTimelineData,
    keyframe,
    animation,
    isSelected,
    isActive,
    isKeyframeActive,
    parentSelected,
    keyframeIndex,
    timelineWidth,
    layer,
    animationKey,
    hasOverwrite,
    dataCyPrefix
}: Props): JSX.Element => {
    /**
     * Update the animation and sort it.
     * @param animation - The animation array.
     * @param stamp - The new stamp value.
     * @returns The updated animation array.
     */
    const updateAnimation = (animation: Animation[], distance: number): Animation[] => {
        const newAnimation = animation.map((oldKeyframe) => {
            if (oldKeyframe.id === keyframe.id) {
                const newStamp = roundToNearestDecimal(oldKeyframe.stamp + distance, SECOND_ROUNDING_PRECISION);
                const time = TimelineHelpers.stampToSeconds(newStamp);
                TimelineHelpers.seekTo(time);
                return { ...oldKeyframe, stamp: newStamp };
            }

            return oldKeyframe;
        });

        return TimelineHelpers.checkSameTimestamp(newAnimation);
    };

    /**
     * Update the stamp of the keyframe in the store.
     * @param newStamp - The new stamp value.
     */
    const updateStampInStore = (distance: number) => {
        const frameType = getTemplateData<View['frameType']>('view.frameType');
        const formatToUpdate = FormatHelpers.currentFormat();

        const animation = TemplateDesignerStore.getModelWithFallback<Animation[]>([
            `layerProperties.${formatToUpdate}.${frameType}.${layer.key}.animations.${animationKey}`,
            `layerProperties.general.${frameType}.${layer.key}.animations.${animationKey}`
        ]);

        const newAnimation = updateAnimation(animation, distance);
        const uniqueAnimation = TimelineHelpers.checkSameTimestamp(newAnimation);
        TemplateDesignerStore.save([`layerProperties.${formatToUpdate}.${frameType}.${layer.key}.animations.${animationKey}`, uniqueAnimation]);
    };

    /**
     * Update the stamp of the keyframe in the state.
     * @param newStamp - The new stamp value.
     */
    const updateStampInState = (distance: number) => {
        setTimelineData((oldtimelineData) => {
            const newTimelineData = cloneDeep(oldtimelineData);

            const formatToUpdate = FormatHelpers.currentFormat();
            const frameType = getTemplateData<View['frameType']>('view.frameType');

            const oldAnimation = TemplateDesignerStore.getModelWithFallback<Animation[]>([
                `layerProperties.${formatToUpdate}.${frameType}.${layer.key}.animations.${animationKey}`,
                `layerProperties.general.${frameType}.${layer.key}.animations.${animationKey}`
            ]);

            newTimelineData[layer.key].animations[animationKey] = updateAnimation(oldAnimation, distance);

            if (formatToUpdate !== 'general') {
                const formatOverwrites = newTimelineData[layer.key].formatOverwrites ?? [];
                formatOverwrites.push(animationKey);
                newTimelineData[layer.key].formatOverwrites = formatOverwrites;
            }

            return newTimelineData;
        });
    };

    const { dragRef } = useTimelineDrag({
        stamp: keyframe.stamp,
        timelineWidth,
        onDragEnd: updateStampInStore,
        onDrag: updateStampInState,
        snapPoints: [0, 1]
    });

    const drawLine = animation.length > 1 && keyframeIndex < animation.length - 1;
    const leftPosition = timelineWidth * keyframe.stamp;

    return (
        <div className="template-designer__timeline-keyframe" style={{ left: `${leftPosition}px` }}>
            <KeyframeDot
                dataCyPrefix={dataCyPrefix}
                dragRef={dragRef}
                left={leftPosition}
                stamp={keyframe.stamp}
                isSelected={isSelected}
                parentSelected={parentSelected}
                isActive={isActive}
                isKeyframeActive={isKeyframeActive}
                hasOverwrite={hasOverwrite}
                layer={layer}
                keyframeKey={keyframe.id}
                animationKey={animationKey}
            />
            {drawLine && (
                <div
                    className={classNames('template-designer__timeline-keyframe__line', {
                        'template-designer__timeline-keyframe__line--isActive': isActive,
                        'template-designer__timeline-keyframe__line--parent-selected': (parentSelected || isSelected) && !isActive && !hasOverwrite,
                        'template-designer__timeline-keyframe__line--parent-selected-overwrite': (parentSelected || isSelected) && !isActive && hasOverwrite,
                        'template-designer__timeline-keyframe__line--has-overwrite': isSelected && hasOverwrite
                    })}
                    style={{ left: `${leftPosition}px`, width: `${timelineWidth * animation[keyframeIndex + 1].stamp - leftPosition}px` }}
                />
            )}
        </div>
    );
};

export { Keyframe };
