import React, { useEffect, useRef, useState } from 'react';
import { VirtualItem } from '@tanstack/react-virtual';
import classNames from 'classnames';
import { FontType, TemplateFont } from 'components/template-designer/types/font.type';
import Template, { DesignerSettings } from 'components/template-designer/types/template.type';
import ListItemButton from 'components/ui-components-v2/ListItemButton';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Typography from 'components/ui-components-v2/Typography';
import Translation from 'components/data/Translation';
import MenuWithHelperText from 'components/ui-components-cape/MenuWithHelperText';
import IconButton from 'components/ui-components-v2/IconButton';
import Icon from 'components/ui-components-v2/Icon';
import TemplateDesignerStore from 'components/template-designer/data/template-designer-store';
import { getTemplateData } from 'components/template-designer/helpers/data.helpers';
import { FontHelpers } from 'components/template-designer/helpers/font.helpers';
import { DynamicLayerInput, SelectSettings } from 'components/template-designer/types/dynamicLayer.type';
import { Font } from './font-picker-font-list';
import '../styles/font-picker-font-item.scss';

interface Props {
    option: VirtualItem;
    fontSource?: FontType;
    font: Font;
    fontFamily?: string | string[];
    onHover?: () => void;
    onSelectFontFamily: (fontSource: FontType, fontFamily: string, fontLabel: string) => void;
}

const FONT_PICKER_TOOLTIP_DELAY = 2000;

const FontPickerFontItem = ({ option, fontSource, font, fontFamily, onHover, onSelectFontFamily }: Props) => {
    const [editTitle, setEditTitle] = useState(false);

    const fontHoverTimeout = useRef<number | null>(null);
    const titleInput = useRef<HTMLInputElement>(null);

    /**
     * Select the title input when the user double clicks on the font item.
     */
    useEffect(() => {
        if (!editTitle || !titleInput || !titleInput.current) return;
        titleInput.current.select();
    }, [editTitle]);

    /**
     * Handle the font hover timeout.
     */
    const handleFontHover = () => {
        if (!onHover) return;
        if (fontHoverTimeout.current) clearTimeout(fontHoverTimeout.current);
        fontHoverTimeout.current = window.setTimeout(() => {
            onHover?.();
        }, 300);
    };

    /**
     * Cancel the font hover timeout.
     */
    const handleCancelFontHover = () => {
        if (fontHoverTimeout.current) clearTimeout(fontHoverTimeout.current);
    };

    /**
     * Change the title of a template font.
     * @param fontKey - The key of the font to change the title of.
     * @param newTitle - The new title of the font.
     */
    const handleChangeTitle = (fontKey: TemplateFont['key'], newTitle: TemplateFont['title']) => {
        if (newTitle === '') return;
        const currentTemplateFonts = getTemplateData<DesignerSettings['templateFonts']>('designerSettings.templateFonts');
        const newTemplateFonts = currentTemplateFonts?.map((font) => {
            if (font.key === fontKey) {
                return {
                    ...font,
                    title: newTitle
                };
            }

            return font;
        });

        const dynamicLayers = getTemplateData<Template['dynamicLayers']>('dynamicLayers');

        function renameFontInDynamicLayers(dynamicLayers: DynamicLayerInput[]) {
            dynamicLayers.forEach((dynamicLayer) => {
                if (dynamicLayer.attribute === 'fontFamily') {
                    (dynamicLayer.settings as SelectSettings).options.forEach((option) => {
                        if (option.key === fontKey) {
                            option.value = newTitle;
                        }
                    });
                }

                if (dynamicLayer.type === 'group') {
                    renameFontInDynamicLayers(dynamicLayer.children);
                }
            });
        }

        Object.values(dynamicLayers).forEach((dynamicLayers) => renameFontInDynamicLayers(dynamicLayers));

        TemplateDesignerStore.save(
            [
                ['designerSettings.templateFonts', newTemplateFonts],
                ['dynamicLayers', dynamicLayers]
            ],
            { saveToHistory: false }
        );
        setEditTitle(false);
    };

    /**
     * Handle the keydown event on the font item.
     * When the user presses Enter, the title will be changed.
     * When the user presses Escape, the title will not be changed.
     * @param event - The keydown event.
     */
    const handleKeydown = (key: string, fontKey: TemplateFont['key'], newTitle: TemplateFont['title']) => {
        if (key === 'Enter') {
            handleChangeTitle(fontKey, newTitle);
        }

        if (key === 'Escape') {
            setEditTitle(false);
        }
    };

    /**
     * Remove a template font.
     * @param fontKey - The key of the font to remove.
     */
    const handleRemoveTemplateFont = (fontKey: TemplateFont['key']) => {
        const template = getTemplateData<Template>();
        const currentTemplateFonts = getTemplateData<DesignerSettings['templateFonts']>('designerSettings.templateFonts');
        const newTemplateFonts = currentTemplateFonts?.filter((font) => font.key !== fontKey);
        TemplateDesignerStore.save(['designerSettings.templateFonts', newTemplateFonts], { saveToHistory: false });
        template.designerSettings.templateFonts = newTemplateFonts;
        FontHelpers.loadFonts(template);
    };

    const selected = (() => {
        if (editTitle) return false;
        if (typeof fontFamily === 'string' && fontFamily === font.value) return true;
        if (Array.isArray(fontFamily) && fontFamily.includes(font.value)) return true;
        return false;
    })();

    return (
        <Tooltip key={option.key} enterDelay={FONT_PICKER_TOOLTIP_DELAY} title={font.label} placement="right" disableInteractive>
            <ListItemButton
                className={classNames('template-designer__font-picker-font-item', {
                    'template-designer__font-picker-font-item--selected': selected
                })}
                style={{
                    // Used for the virtualizer.
                    height: `${option.size}px`,
                    transform: `translateY(${option.start}px)`
                }}
                selected={selected}
                onClick={() => {
                    if (editTitle) return;
                    onSelectFontFamily(font.fontSource, font.value, font.label);
                }}
                onMouseOver={handleFontHover}
                onMouseLeave={handleCancelFontHover}
                data-cy={`templateDesigner-fontFamily-${font.value}-button`}>
                {font.image ? (
                    <img className="template-designer__font-picker-font-item__image" src={font.image} />
                ) : (
                    <>
                        {editTitle && (
                            <input
                                className="template-designer__font-picker-font-item__input"
                                ref={titleInput}
                                defaultValue={font.label}
                                onBlur={(event) => handleChangeTitle(font.key, event.target.value)}
                                onKeyDown={(event) => handleKeydown(event.key, font.key, (event.target as HTMLInputElement).value)}
                            />
                        )}
                        {!editTitle && (
                            <Typography
                                variant="body2"
                                style={{ fontFamily: font.value }}
                                className="template-designer__font-picker-font-item__text"
                                onDoubleClick={() => setEditTitle(true)}>
                                {font.label}
                            </Typography>
                        )}
                    </>
                )}

                {fontSource === 'templateFonts' && (
                    <MenuWithHelperText
                        trigger={
                            <IconButton size="small" className="template-designer__font-picker-font-item__menu">
                                <Icon fontSize="small">more_vert</Icon>
                            </IconButton>
                        }
                        items={[
                            {
                                key: 'editName',
                                label: Translation.get('sidebarRight.inputs.fontPicker.editName', 'template-designer'),
                                icon: 'edit',
                                onClick: () => setEditTitle(true)
                            },
                            {
                                key: 'delete',
                                className: 'template-designer__font-picker-font-item__menu__delete',
                                label: Translation.get('actions.remove', 'common'),
                                icon: 'delete',
                                onClick: () => handleRemoveTemplateFont(font.key)
                            }
                        ]}
                    />
                )}
            </ListItemButton>
        </Tooltip>
    );
};

export { FontPickerFontItem };
