import React, { useEffect, useState } from 'react';
import { PopperProps } from '@mui/material';
import Translation from 'components/data/Translation';
import { FontType } from 'components/template-designer/types/font.type';
import IconButton from 'components/ui-components-v2/IconButton';
import Icon from 'components/ui-components-v2/Icon';
import SearchField from 'components/ui-components/SearchField';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import Template, { DesignerSettings, TemplateData } from 'components/template-designer/types/template.type';
import Divider from 'components/ui-components-v2/Divider';
import User from 'components/data/User';
import Tooltip from 'components/ui-components-v2/Tooltip';
import { PopupPanel } from '../../ui-components/popup-panel';
import { FontPickerFontList } from './components/font-picker-font-list';
import { FontPickerFontSource } from './components/font-picker-font-source';
import { FontPickerAddFontDialog } from './components/font-picker-add-font-dialog';
import './styles/main.scss';

interface Props {
    trigger: React.ReactElement;
    fontSource?: FontType;
    fontFamily?: string | string[];
    onChange: (fontSource: FontType, fontFamily: string, fontName: string) => void;
    onChangePreview?: (fontSource: FontType, fontFamily: string) => void;
    placement?: PopperProps['placement'];
    closeOnSelect?: boolean;
}

const FontPicker = ({ trigger, fontSource, fontFamily, onChange, onChangePreview, placement = 'left-start', closeOnSelect }: Props) => {
    const { brandGuide, brands, templateFonts } = useComponentStore<{
        brandGuide: Template['brandGuide'];
        brands: TemplateData['brands'];
        templateFonts: DesignerSettings['templateFonts'];
    }>('TemplateDesigner', {
        fields: {
            brandGuide: 'brandGuide',
            brands: 'templateData.brands',
            templateFonts: 'designerSettings.templateFonts'
        }
    });

    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentFontSource, setCurrentFontSource] = useState<FontType>(fontSource ?? 'standard');

    /**
     * Update the current font source when the font source prop changes.
     */
    useEffect(() => {
        if (fontSource) setCurrentFontSource(fontSource);
    }, [fontSource]);

    /**
     * Reset the search term when the font picker is opened or closed.
     */
    useEffect(() => {
        setSearchTerm('');
    }, [open]);

    const hasBrandManagement = User.hasRight('brandManagement');

    return (
        <PopupPanel
            className="template-designer__font-picker"
            title={Translation.get('sidebarRight.inputs.font', 'template-designer')}
            button={React.cloneElement(trigger, { onClick: () => setOpen(true) })}
            placement={placement}
            open={open}
            headerActions={
                <div className="template-designer__font-picker__header-actions">
                    <FontPickerAddFontDialog
                        trigger={
                            <Tooltip title={Translation.get('sidebarRight.inputs.fontPicker.addTemplateFontTooltip', 'template-designer')}>
                                <IconButton size="small">
                                    <Icon fontSize="small">add</Icon>
                                </IconButton>
                            </Tooltip>
                        }
                        setCurrentFontSource={setCurrentFontSource}
                        onAdd={onChange}
                    />
                    {hasBrandManagement && (
                        <Tooltip title={Translation.get('sidebarRight.inputs.fontPicker.openBrandManagementTooltip', 'template-designer')}>
                            <IconButton size="small" onClick={() => window.open('/settings/brand-management', '_blank')?.focus()}>
                                <Icon fontSize="small">menu_book</Icon>
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
            }>
            <div className="template-designer__font-picker__search">
                <SearchField
                    searchPlaceholder={`${Translation.get('labels.search', 'common')} ${Translation.get('sidebarRight.inputs.fontPicker.fonts', 'template-designer').toLowerCase()}`}
                    onChange={(_, searchTerm) => setSearchTerm(searchTerm)}
                    wait={false}
                    size="small"
                    dataCy="templateDesigner-fontSearch-input"
                />
            </div>

            {searchTerm === '' && (
                <>
                    <Divider className="template-designer__font-picker__divider" />
                    <div className="template-designer__font-picker__font-source">
                        <FontPickerFontSource brandGuide={brandGuide} brands={brands} fontSource={currentFontSource} onChange={setCurrentFontSource} />
                    </div>
                </>
            )}

            <Divider className="template-designer__font-picker__divider" />

            <FontPickerFontList
                brandGuide={brandGuide}
                templateFonts={templateFonts}
                fontSource={currentFontSource}
                fontFamily={fontFamily}
                onChangePreview={onChangePreview}
                onChange={(fontSource, fontFamily, fontName) => {
                    onChange(fontSource, fontFamily, fontName);

                    // Close the font picker when a font is selected.
                    if (closeOnSelect) setOpen(false);
                }}
                setCurrentFontSource={setCurrentFontSource}
                searchTerm={searchTerm}
            />
        </PopupPanel>
    );
};

export { FontPicker };
